import CellMain from 'src/@components/table/CellMain'
import CellTextEditor from 'src/@components/table/CellTextEditor'
import CellSelectEditor from 'src/@components/table/CellSelectEditor'
import CellTag from 'src/@components/table/CellTag'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Typography from 'antd/es/typography/Typography'
import { foldersStatusOptions } from 'src/folders/FoldersForm'
import Avatar from 'src/@components/Avatar'
import { Tooltip, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { supabase } from 'src/supabase'
import { useCompanies, useDoctors, useProfile, useTranscribers } from 'src/profiles/ProfilesApi'
import { useUI } from 'src/layout/UI'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/auth/Auth'
import { useQueryClient } from '@tanstack/react-query'
import { useSettings } from 'src/settings/SettingsApi'

const { APP_API_URL } = import.meta.env

export default [
  {
    field_: '',
    headerComponentParams: { icon: light('file') },
    filterOption: {
      disable: true,
    },
    width: 150,
    filter: ({ section }) => ['completed', '*'].includes(section.value),
    cellRenderer: ({ value, data }) => {
      const [downloadLoading, setDownloadLoading] = useState(false)
      const { t } = useTranslation()
      const { messageApi } = useUI()

      const downloadReport = async () => {
        setDownloadLoading(true)
        const { data: dataFile, error } = await supabase
          .storage
          .from('folders')
          .download(data.report_path)

        setTimeout(() => {
          if (dataFile) {
            const blobUrl = window.URL.createObjectURL(dataFile);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = data.report_path.split('/')[data.report_path.split('/').length - 1]; // Set your desired filename and extension here
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(blobUrl);
          } else {
            messageApi.open({
              type: 'error',
              content: error.message,
              duration: 4,
            })
          }
          setDownloadLoading(false)
        }, 2000)
      }

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {data.report_path && <Button size="small" disabled={downloadLoading} loading={downloadLoading} onClick={(e) => [e.stopPropagation(), e.preventDefault(), downloadReport()]} type="text" block icon={<FontAwesomeIcon icon={solid('cloud-arrow-down')} />}>{t('download')}</Button>}
        </div>
      )
    }
  },
  {
    field_: '',
    headerComponentParams: {},
    filterOption: {
      disable: true,
    },
    width: 120,
    filter: ({ section, profile }) => ['available'].includes(section.value) && profile.universus,
    cellRenderer: ({ value, data }) => {
      const { messageApi, disableAccept, setDisableAccept } = useUI()
      const { session } = useAuth()
      const navigate = useNavigate()
      const [loading, setLoading] = useState(false)
      const queryClient = useQueryClient()
      const { data: settings } = useSettings()

      const acceptFolder = async () => {
        setLoading(true)
        setDisableAccept(true)
        const response = await fetch(`${APP_API_URL}/accept-folder/${data.id}`, {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        }).then(res => res.json()).catch(e => {
          setLoading(false)
          setDisableAccept(false)

          messageApi.open({
            type: 'error',
            content: e.message,
            duration: 4,
          })
        })

        setLoading(false)
        setDisableAccept(false)

        if (response.data && !response.error) {
          messageApi.open({
            type: 'success',
            content: "Dossier accepté",
            duration: 4,
          })

          queryClient.setQueryData(['folders', 'available'], (old) => (old.map(folder => folder.id === data.id ? {
            ...folder,
            work: {
              ...folder.work,
              status: 'ongoing'
            }
          } : folder)))

          queryClient.setQueryData(['transcriber'], (old) => ({
            ...old,
            current: {
              ...data,
              work: {
                ...data.work,
                status: 'ongoing'
              }
            }
          }))

          // Redirect because the Navigation.jsx redirection is not handled for administrators
          if (settings?.permissions['read-folders']) {
            navigate('/transcription/' + data.pydio_id)
          }
        } else {
          messageApi.open({
            type: 'error',
            content: response.error.message,
            duration: 4,
          })
        }
      }

      if (data.work.status === 'processing') {
        return <div></div>
      }

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {<Button disabled={disableAccept} loading={loading} size="small" onClick={(e) => [e.stopPropagation(), e.preventDefault(), acceptFolder()]} type="text" block icon={<FontAwesomeIcon icon={solid('check-to-slot')} />}>{"Accepter"}</Button>}
        </div>
      )
    }
  },
  {
    field: 'status',
    width: 160,
    filter: ({ section, profile }) => !['completed'].includes(section.value),
    headerComponentParams: { icon: light('bars-staggered') },
    cellRenderer: ({ value, data }) => {
      const { t } = useTranslation()
      const { data: profile } = useProfile()
      const { pathname } = useLocation()

      let status

      if (data.work && profile.universus && !pathname.includes('completed')) {
        if (data.work.status === 'processing') {
          status = 'processing'
        } if (data.work.status === 'available' && data.work.service === 'translate') {
          status = 'translate'
        } else if (data.work.status === 'available' && data.work.transcriber) {
          status = 'correction'
        } else if (data.work.status === 'available' && !data.work.transcriber) {
          status = data.work.service
        } else if (data.work.status === 'ready') {
          status = "report"
        } else if (data.work.status === 'ongoing') {
          if (!data.work.reviewer) {
            status = data.work.service
          } else {
            status = "correction"
          }
        }
      } else {
        status = value
      }

      return <div className='flex items-center gap-1'>
        <CellTag value={foldersStatusOptions[status]} />

        {data.emergency && <Tooltip title={t('folders.fields.emergency')} placement="right" arrow={false}>
          <FontAwesomeIcon style={{ color: '#c0392b' }} icon={light('siren-on')} />
        </Tooltip>}
      </div>
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'status',
      values: Object.values(foldersStatusOptions)
    },
  },
  {
    field: 'duration',
    filter: ({ section, profile }) => profile.universus,
    headerComponentParams: { icon: light('timer') },
    width: 100,
    cellRenderer: ({ value }) => {
      const { t } = useTranslation()

      const minutes = Math.floor(value / 60);
      const remainingSeconds = value % 60;

      return (
        <div className="h-full w-full">
          {value && `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}
          {!value && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
  },
  {
    field: 'doctor',
    width: 200,
    sortField: 'doctor(id)',
    headerComponentParams: { icon: light('user-doctor') },
    filter: ({ session, profile }) => !session.user.user_metadata.doctor_id || profile.universus,
    cellRenderer: ({ value }) => {
      const { t } = useTranslation()

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {!!value && <>
            <Avatar profile={value.profile} size={30} />
            {`${value.profile.gender === 'M' ? 'Dr' : 'Dre'} ${value.profile.first_name} ${value.profile.last_name}`}
          </>}

          {!value && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'doctor_id',
      valuesHook: useDoctors,
      valuesLabel: (value) => ({ label: value?.profile?.last_name, value: value.id }),
    },
  },
  {
    field: 'company',
    headerComponentParams: { icon: light('hospital') },
    width: 120,
    sortField: 'company(name)',
    filter: ({ session, profile }) => profile.universus,
    cellRenderer: ({ value }) => {
      return (
        <div className="h-full w-full flex">
          {value?.name}
        </div>
      )
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'company_id',
      valuesHook: useCompanies,
      valuesLabel: (value) => ({ label: value.name, value: value.id }),
    },
  },
  {
    field: 'reference',
    width: 120,
    filter: ({ section, profile }) => true,
    headerComponentParams: { icon: light('hashtag') },
    cellRenderer: ({ value, data }) => {
      const { t } = useTranslation()

      return (
        <div className="h-full flex gap-2 items-center cursor-pointer">
          {value}
          {!value && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'text',
      defaultValue: '',
      field: 'reference'
    },
  },
  {
    field: 'pydio',
    sortField: 'pydio_id',
    width: 100,
    headerComponentParams: { icon: light('planet-ringed') },
    filter: ({ session, profile }) => profile.universus,
    cellRenderer: ({ value, data }) => {
      return (
        <div className="h-full w-full flex gap-2 items-center">
          {data.pydio_id}
        </div>
      )
    },
    filterOption: {
      type: 'text',
      defaultValue: '',
      field: 'pydio_id'
    },
  },
  {
    field: 'transcriber',
    sortField: 'transcriber(id)',
    headerComponentParams: { icon: light('user-pen') },
    filter: ({ session, profile, section }) => profile.universus && section.value !== 'available',
    cellRenderer: ({ value, data }) => {
      const { t } = useTranslation()
      const transcriber = data.work && data.work.transcriber

      const last_name = transcriber?.profile?.last_name || ''
      const first_name = transcriber?.profile?.first_name || ''

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {!!transcriber && transcriber.profile && <>
            <Avatar profile={transcriber.profile} size={30} />
            {first_name} {last_name.trim().charAt(0).toUpperCase()}{!!last_name.trim().length ? '.' : ''}
          </>}

          {!transcriber && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'transcriber_id',
      valuesHook: useTranscribers,
      valuesLabel: (value) => ({ label: value?.profile?.last_name + ' ' + value?.profile?.first_name, value: value.id }),
    },
  },
  {
    field: 'reviewer',
    sortField: 'reviewer(id)',
    headerComponentParams: { icon: light('user-magnifying-glass') },
    filter: ({ session, profile, section }) => profile.universus && section.value !== 'available',
    cellRenderer: ({ value, data }) => {
      const { t } = useTranslation()
      const reviewer = data.work && data.work.reviewer

      const last_name = reviewer?.profile?.last_name || ''
      const first_name = reviewer?.profile?.first_name || ''

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {!!reviewer && reviewer.profile && <>
            <Avatar profile={reviewer.profile} size={30} />
            {first_name} {last_name.trim().charAt(0).toUpperCase()}{!!last_name.trim().length ? '.' : ''}
          </>}

          {!reviewer && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'reviewer_id',
      valuesHook: useTranscribers,
      valuesLabel: (value) => ({ label: value?.profile?.last_name + ' ' + value?.profile?.first_name, value: value.id }),
    },
  },
  {
    field: 'translater',
    sortField: 'translater(id)',
    headerComponentParams: { icon: light('user-doctor-message') },
    filter: ({ session, profile, section }) => profile.universus && section.value !== 'available',
    cellRenderer: ({ value, data }) => {
      const { t } = useTranslation()
      const translater = data.work && data.work.translater

      const last_name = translater?.profile?.last_name || ''
      const first_name = translater?.profile?.first_name || ''

      return (
        <div className="h-full w-full flex gap-2 items-center">
          {!!translater && translater.profile && <>
            <Avatar profile={translater.profile} size={30} />
            {first_name} {last_name.trim().charAt(0).toUpperCase()}{!!last_name.trim().length ? '.' : ''}
          </>}

          {!translater && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'translater_id',
      valuesHook: useTranscribers,
      valuesLabel: (value) => ({ label: value?.profile?.last_name + ' ' + value?.profile?.first_name, value: value.id }),
    },
  },
  {
    field: 'patient',
    filter: ({ section, profile, settings }) => !profile.universus || (['completed'].includes(section.value) || (settings?.permissions && settings?.permissions['read-folders'])),
    specificName: ['CEOM'],
    headerComponentParams: { icon: light('user') },
    filterOption: {
      type: 'text',
      defaultValue: '',
      field: 'patient'
    },
  },
  {
    field: 'assessment_date',
    specificName: ['CEOM'],
    filter: ({ section, profile }) => !profile.universus,
    headerComponentParams: { icon: light('calendar') },
    width: 160,
    cellRenderer: ({ value }) => {
      const date = value && DateTime.fromISO(value)
      const { t } = useTranslation()
      return (
        <div className="h-full w-full">
          {date && date.toLocaleString(DateTime.DATE_MED)}
          {!date && <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>}
        </div>
      )
    },
    filterOption: {
      type: 'date',
      defaultValue: null,
      field: 'assessment_date'
    },
  },
  {
    field: 'emergency',
    noTable: true,
    headerComponentParams: { icon: light('siren-on') },
    filterOption: {
      type: 'boolean',
      defaultValue: true,
      field: 'emergency'
    },
  },
  {
    field: 'type',
    headerComponentParams: { icon: light('circle-dot') },
    cellRenderer: ({ value }) => {
      const { t } = useTranslation()

      return (
        <div className="h-full w-full flex gap-2">
          {t('folders.fields.type-values.' + value)}
        </div>
      )
    },
    filter: ({ section, profile }) => !profile.universus,
    filterOption: {
      type: 'select',
      defaultValue: [],
      field: 'type',
      values: [
        { label: 'folders.fields.type-values.transcribe', value: 'transcribe' },
        { label: 'folders.fields.type-values.translate', value: 'translate' }
      ]
    },
  },
  {
    field: 'created_at',
    headerComponentParams: { icon: light('calendar') },
    width: 160,
    cellRenderer: ({ value }) => {
      const date = value && DateTime.fromISO(value)
      const { t } = useTranslation()
      return (
        <div className="h-full w-full">
          {date && date.toLocaleString(DateTime.DATETIME_MED)}
        </div>
      )
    },
    filterOption: {
      type: 'date',
      defaultValue: null,
      field: 'created_at'
    },
  },
  {
    field: 'completed_at',
    filter: ({ section }) => ['completed', '*'].includes(section.value),
    headerComponentParams: { icon: light('calendar') },
    width: 160,
    cellRenderer: ({ value }) => {
      const date = value && DateTime.fromISO(value)
      const { t } = useTranslation()
      return (
        <div className="h-full w-full">
          {date && date.toLocaleString(DateTime.DATETIME_MED)}
        </div>
      )
    },
    filterOption: {
      type: 'date',
      defaultValue: null,
      field: 'created_at'
    },
  },
]
